/* ==============================
© Jens Geyer / 2021
============================== */

@media screen {
  .designPatterns-facade-section {
    margin-top: 2rem;
  }

  .designPatterns-facade-section > .wrapper {
    padding-left: 1;
    padding-right: 1;
  }

  .designPatterns-facade-content {
    background: #ffffff;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;

    text-align: left;
    margin-bottom: 2rem;
    border: 1px solid #193661;
    border-radius: 3px;
  }

  .designPatterns-facade-content-1 {
    margin-bottom: 1rem;
  }

  .designPatterns-facade-heading {
    color: #0055b1;
    padding: 0;
    margin: 0;
  }

  .designPatterns-facade-heading-1 {
    font-weight: bold;
    padding: 0;
    margin: 0;
  }

  .designPatterns-facade-heading-2 {
    font-weight: bold;
    padding: 0;
    margin: 0;
  }

  .designPatterns-facade-heading-wrapper {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .designPatterns-facade-heading-wrapper-child-1 {
    text-align: left;
  }
  .designPatterns-facade-heading-wrapper-child-2 {
    text-align: left;
  }
  .designPatterns-facade-heading-wrapper-child-3 {
    text-align: left;
  }
  .designPatterns-facade-heading-wrapper-child-4 {
    text-align: right;
  }
}
/* end @media */

@media screen and (min-width: 768px) {
  .designPatterns-facade-section {
    margin-top: 1rem;
  }

  .designPatterns-facade-section > .wrapper {
    padding-left: 1rem;
    padding-right: 0;
  }

  .designPatterns-facade-content {
    background: #ffffff;
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    text-align: left;
    margin-bottom: 2rem;

    border-bottom: 1px solid #c0c0c0;
    border-radius: unset;
  }

  .designPatterns-facade-content-1 {
    margin-bottom: 1rem;
  }

  .designPatterns-facade-heading-1 {
    font-weight: bold;
    padding: 0;
    margin: 0;
  }

  .designPatterns-facade-heading-2 {
    font-weight: bold;
    padding: 0;
    margin: 0;
  }

  .designPatterns-facade-heading {
    color: #0055b1;
    padding: 0;
    margin: 0;
  }

  .designPatterns-facade-heading-wrapper {
    display: grid;
    grid-template-columns: auto 5rem 7rem 1.5rem;
    grid-template-areas: 'child1 child2 child3 child4';
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .designPatterns-facade-heading-wrapper-child-1 {
    grid-area: child1;
    text-align: left;
    margin-bottom: 0.2rem;
  }
  .designPatterns-facade-heading-wrapper-child-2 {
    grid-area: child2;
    text-align: left;
    margin-bottom: 0.2rem;
  }
  .designPatterns-facade-heading-wrapper-child-3 {
    grid-area: child3;
    text-align: left;
    margin-bottom: 0.2rem;
  }
  .designPatterns-facade-heading-wrapper-child-4 {
    grid-area: child4;
    text-align: left;
    margin-bottom: 0.2rem;
  }
}
/* end @media */
