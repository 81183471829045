/* ==============================
© Jens Geyer / 2021
============================== */

@media screen {
  .intro-content {
    background: #ffffff;
  }

  .intro-content-1 {
    margin-bottom: 0.4rem;
  }

  .intro-content-2 {
    margin-bottom: 1rem;
  }
} /* Ende @media */

@media screen and (min-width: 768px) {
  .intro-content {
    background: #ffffff;
  }

  .intro-content-1 {
    margin-bottom: 0.4rem;
  }

  .intro-content-2 {
    margin-bottom: 1rem;
  }
}
/* end @media */
