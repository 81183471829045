/* ==============================
© Jens Geyer / 2021
============================== */

@media screen {
  .content-intro-section {
    margin-top: 0rem;
  }

  .content-intro-section > .wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .img-section-img {
    width: 100%;
    min-height: 10rem;
    box-shadow: 0 6px 6px #0c0c0c;
  }
}
/* end @media */

@media screen and (min-width: 768px) {
  .content-intro-section > .wrapper {
    padding: 0 1rem;
  }
}
/* end @media */
