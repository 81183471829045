/* ==============================
© Jens Geyer / 2021
============================== */

@media screen {
  .info-boxes-section {
    margin-top: 1rem;
    background-color: #eee;
  }

  .boxes {
    padding: 1rem 0;
  }

  .box {
    background-color: white;
    padding: 1rem;
    margin: 1rem 0;
    display: flex;
    flex-flow: column;
    border: 1px solid #193661;
    border-radius: 3px;
  }
  .box i {
    text-align: center;
    font-size: 3rem;
  }
  .box p {
    text-align: center;
  }
  .box span {
    text-align: center;
  }
  .box-heading {
    color: #193661;
    text-align: center;
  }
  .box-content {
    margin-bottom: 1rem;
  }
}
/* end @media */

@media screen and (min-width: 768px) {
  .info-boxes-section {
    margin-top: 2rem;
    background-color: #eee;
  }

  .boxes {
    padding: 1rem 0;
    margin-bottom: 1rem;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  }

  .box {
    background-color: white;
    padding: 1rem;
    margin: 1rem 0;
    display: flex;
    flex-flow: column;
  }
  .box i {
    text-align: center;
    font-size: 3rem;
  }
  .box p {
    text-align: center;
  }
  .box span {
    text-align: center;
  }
  .box-heading {
    color: #193661;
    text-align: center;
  }
  .box-content {
    margin-bottom: 1rem;
  }
}
/* end @media */
