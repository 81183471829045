/* ==============================
© Jens Geyer / 2021
============================== */

@media screen {
  .designPatterns-menubutton {
    display: block;
    background: #c0c0c0;
    color: #000000;

    font-family: inherit;
    font-size: inherit;
    text-align: center;

    padding: 0.5rem 1rem;
    border: 0;
    border-radius: 0.25rem;
  }

  /** 
  * design for list 
  */

  .designPatterns-navigation-list {
    display: flex;
    flex-flow: column nowrap;

    padding: 0;
    margin: 0;
  }
  .designPatterns-navigation-list-li {
    list-style: none;
  }

  /**
  * design for links 
  */

  .designPatterns-navigation-list-span {
    display: block;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;

    min-width: 72px;
    background: #eee;
    color: #193661;

    padding: 0.5rem 1rem;
  }

  .designPatterns-navigation-list-h1 {
    display: block;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;

    min-width: 72px;
    background: #eee;
    color: #0055b1;
    font-weight: bold;

    padding: 0.5rem 1rem;
  }

  .designPatterns-you-are-here {
    background: #c0c0c0;
    color: #193661;
  }

  .designPatterns-navigation-list-span:active {
    background: #dfdfdf;
    color: #193661;
  }

  .designPatterns-navigation-list-span:hover {
    cursor: pointer;
    background: #dfdfdf;
    color: #193661;
    font-weight: bold;
  }

  /**
  * navigation list hide
  */

  .designPatterns-navigation-list {
    max-height: 0;
    overflow: hidden;
  }
  .designPatterns-show-menu + .designPatterns-navigation-list {
    max-height: 100rem;
    transition: max-height 1000ms ease-in-out;
  }
}
/* end @media */

/* ==============================
new media for minimum width 768 pixel
============================== */

@media screen and (min-width: 768px) {
  .designPatterns-menubutton {
    display: block;
    background: #c0c0c0;
    color: #000000;

    font-family: inherit;
    font-size: inherit;
    text-align: center;

    padding: 0.5rem 1rem;
    border: 0;
    border-radius: 0.25rem;
  }

  .designPatterns-navigation-list-span {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }

  .designPatterns-navigation-list-h1 {
    padding-top: 0.5rem;
    padding-bottom: 0.1rem;
  }

  .designPatterns-show-menu + .designPatterns-navigation-list {
    z-index: 50000;
    position: absolute;
  }
}
/* end @media */
