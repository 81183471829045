/* ==============================
© Jens Geyer / 2021
============================== */

@media screen {
  .contact-section {
    margin-top: 2rem;
  }

  .contact {
    background: #ffffff;
  }

  .contact-intro {
    text-align: left;
    background: #ffffff;
    padding: 1rem;
    margin-bottom: 2rem;
    border: 1px solid #193661;
    border-radius: 3px;
  }

  .contact-data {
    background: #eee;
    padding: 1rem;
    margin-bottom: 2rem;

    border: 1px solid #193661;
    border-radius: 3px;
  }

  .contact-form {
    background: #ffffff;
    padding: 1rem;

    border: 1px solid #193661;
    border-radius: 3px;
  }
}
/* end @media */

@media screen and (min-width: 768px) {
  .contact-section {
    margin-top: 1rem;
  }

  .contact {
    display: grid;

    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      'intro intro'
      'col1 col2';

    grid-column-gap: 3rem;
    grid-row-gap: 1rem;
  }

  .contact-intro {
    text-align: left;
    background: #ffffff;
    border: none;
    padding: 1rem;
    grid-area: intro;
    margin-bottom: 2rem;

    border-bottom: 1px solid #3983d1;
    border-radius: unset;
  }

  .contact-data {
    background: #eee;
    padding: 1rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: unset;
    grid-area: col2;
  }

  .contact-form {
    background: #ffffff;
    border: none;
    grid-area: col1;
  }
}
/* end @media */
