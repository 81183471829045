@media screen {
  html {
    box-sizing: border-box;
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  body {
    background: white;
    margin: 0;
    min-height: 100vh;
  }
  .root {
    background: white;
  }
}
