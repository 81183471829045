/* ==============================
© Jens Geyer / 2021
============================== */

@media screen {
  .me-info2-section {
    margin-top: 1rem;
  }

  .me-info2-child {
    text-align: center;
    margin-top: 1rem;
    background-color: #eee;

    border: 1px solid #193661;
    border-radius: 3px;
    padding: 0.5rem;
  }

  .me-info2-heading {
    line-height: 1;
    text-align: left;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin: 0;
  }

  .me-info2-content {
    text-align: left;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    margin: 0;
  }

  .me-info2-content-ul {
    display: grid;
    grid-template-columns: 1rem auto;
    padding: 0;
    margin: 0;
  }

  .me-info2-content-ul-child-1 {
    text-align: left;
  }

  .me-info2-content-ul-child-2 {
    text-align: left;
  }

  .icon-me-info2-content-li {
    list-style: none;
    font-size: 0.7rem;
    color: #0055b1;
  }

  .me-info2-content-li-span {
    font-size: 1rem;
    margin-left: 0.3rem;
  }

  .me-img {
    width: 99%;
    min-height: 10rem;
    box-shadow: 0 6px 6px #0c0c0c;
  }
}
/* end @media */

@media screen and (min-width: 768px) {
  .me-info2-section {
    margin-top: 1rem;
  }

  .me-info2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 0.5rem;

    list-style: none;
    padding: 0;
    margin: 0;
  }

  .me-info2-child {
    text-align: center;
    margin-top: 0;
    background-color: #ffffff;

    border: 1px solid #193661;
    border-radius: 3px;
    padding: 0.5rem;
  }

  .me-info2-heading {
    line-height: 1;
    text-align: left;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin: 0;
  }

  .me-info2-content {
    text-align: left;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    margin: 0;
  }

  .me-img {
    width: 100%;
    min-height: 10rem;
    box-shadow: 0 6px 6px #0c0c0c;
  }
}
/* end @media */
