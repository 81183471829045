/* ==============================
© Jens Geyer / 2021
============================== */

@media screen {
  .contact-form-img {
    width: 6rem;
  }

  .contactForm-not-posted-img {
    flex: 1;
    text-align: center;

    padding-left: 1rem;
    padding-right: 1rem;
    flex: 1;
    margin-bottom: 1rem;
  }

  .contact-form-wrapper {
    background: #ffffff;
    padding: 1rem;

    border: 1px solid #3983d1;
    border-radius: 3px;
  }

  /** 
  * for all 
  */
  .contact-form-p {
    color: #193661;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .contact-form-span {
    color: #000000;
  }

  .contact-form-label {
    display: block;
    cursor: pointer;
    flex: 0 0 7rem;
  }

  .contact-form-div {
    margin-bottom: 1em;
    display: flex;
    flex-flow: wrap;
  }

  /** 
  * input fields
  */

  .contactForm-input {
    font-size: inherit;
    font-family: inherit;
    width: 16rem;
    padding: 0.25rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    flex: 1;
  }

  .contactForm-textarea {
    max-width: 100%;
    min-height: 8rem;
    font-size: inherit;
    font-family: inherit;
    width: 16rem;
    padding: 0.25rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    flex: 1;
  }

  /** 
  * button   
  */

  .contactForm-button {
    font-size: inherit;
    font-family: inherit;
    letter-spacing: 1px;
    cursor: pointer;

    background: #0055b1;
    color: white;

    padding: 0.5rem;
    border: none;
    border-radius: 0.25rem;
    flex: 1;
  }
  .contactForm-button:hover,
  .contactForm-button:focus {
    background: #3983d1;
  }
  .contactForm-button:active {
    background: #a0a0a0;
  }

  .contactForm-error-text {
    color: #d90000;
    padding-left: 1rem;
    padding-right: 1rem;
    flex: 1;
  }

  .contactForm-posted-text {
    border: 1px solid #3983d1;
    border-radius: 0.25rem;
    flex: 1;

    padding-left: 1rem;
    padding-right: 1rem;
    flex: 1;
    margin-bottom: 1rem;
  }
  .contactForm-posted-text-1 {
    color: green;
    padding-top: 0.4rem;
  }
  .contactForm-posted-text-2 {
    text-align: right;
    font-size: smaller;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .contactForm-posted-text-2 span {
    color: #193661;
    text-decoration: none;
  }

  .contactForm-posted-text-2 span:hover {
    cursor: pointer;
    color: #193661;
    font-weight: bold;
  }

  .contactForm-not-posted-text {
    border: 1px solid #3983d1;
    border-radius: 0.25rem;
    flex: 1;

    padding-left: 1rem;
    padding-right: 1rem;
    flex: 1;
    margin-bottom: 1rem;
  }
  .contactForm-not-posted-text-1 {
    color: #d90000;
    padding-top: 0.4rem;
  }
  .contactForm-not-posted-text-2 {
    text-align: right;
    font-size: smaller;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .contactForm-not-posted-text-2 span {
    color: #193661;
    text-decoration: none;
  }

  .contactForm-not-posted-text-2 span:hover {
    cursor: pointer;
    color: #193661;
    font-weight: bold;
  }

  .contactForm-error-text-display-none {
    display: none;
  }
} /* Ende @media */

@media screen and (min-width: 768px) {
  .contact-form-img {
    width: 8rem;
  }
}
/* end @media */
