/* ==============================
© Jens Geyer / 2021
============================== */

@media screen {
  .useful-information-section {
    margin-top: 2rem;
  }

  .useful-information {
    background-color: #eee;
    padding: 1em;
    margin: 1em 0;
    border: 1px solid #3983d1;
    border-radius: 3px;
  }

  .useful-information-content {
    margin-bottom: 0.1rem;
    color: #860909;
    text-align: center;
  }

  .useful-information-heading {
    margin-bottom: 1rem;
    color: #3983d1;
    text-align: center;
  }
  .useful-information-content {
    margin-bottom: 1rem;
    color: #000000;
    text-align: left;
  }

  .loadGif-logo-div {
    margin-top: 6rem;
    margin-bottom: 6rem;
    text-align: center;
  }

  .loadGif-logo {
    width: 150px;
  }
}
/* end @media */

@media screen and (min-width: 768px) {
  .useful-information-section {
    margin-top: 2rem;
  }

  .useful-information {
    background-color: #eee;
    padding: 1em;
    margin: 1em 0;
    border-left: 5px solid #3983d1;
  }

  .useful-information-heading {
    margin-bottom: 1rem;
    color: #3983d1;
    text-align: center;
  }
  .useful-information-content {
    margin-bottom: 1rem;
    color: #000000;
    text-align: left;
  }

  .loadGif-logo-div {
    margin-top: 6rem;
    margin-bottom: 6rem;
    text-align: center;
  }

  .loadGif-logo {
    width: 150px;
  }
}
/* end @media */
