/* ==============================
© Jens Geyer / 2021
footer.css

- navigation stylesheet
# footer navigation
============================== */

@media screen {
  .footer-navigation {
  }
  .footer-navigation-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .footer-navigation-list li {
    margin-right: 1rem;
  }

  .footer-navigation-list li:last-child {
    margin-right: 0;
    margin-left: auto;
  }

  /**
  * design of the links   
  */

  .footer-navigation-list span {
    color: white;
    text-decoration: none;
  }

  .footer-navigation-list span:hover {
    cursor: pointer;
    color: #95c3f5;
    font-weight: bold;
  }
}
/* end @media */

@media screen and (max-width: 768px) {
  .footer-scrollToTop {
    position: fixed;
    bottom: 7px;
    right: 7px;

    background: #193661;

    border: 0;
    border-radius: 0.5rem;
    padding: 0.5rem;
  }
}
/* end @media */
