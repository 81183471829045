/* ==============================
© Jens Geyer / 2021
navigation.css

- navigation stylesheet
# responsive navigation
# base on display:flex
============================== */

@media screen {
  .menubutton {
    display: block;
    background: #193661;
    color: white;

    font-family: inherit;
    font-size: inherit;
    text-align: center;

    padding: 0.5rem 1rem;
    border: 0;
    border-radius: 0.25rem;
    margin-top: 0;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
  }

  .page-navigation > .wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  /**
  * basic formatting 
  */

  .page-navigation {
    position: -webkit-sticky;
    position: sticky;
    top: 0;

    background: #3983d1;
    color: white;

    box-shadow: 0 2px 6px #3983d1;

    padding: 0.5rem 0 0 0;
    margin: 0;

    z-index: 10;
  }

  /** 
  * design for list 
  */

  .page-navigation-list {
    display: flex;
    flex-flow: column nowrap;

    padding: 0;
    margin: 0;
  }
  .page-navigation-list li {
    list-style: none;
  }

  /**
  * design for links 
  */

  .page-navigation-list span {
    display: block;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;

    min-width: 72px;
    background: #3983d1;
    color: white;

    padding: 0.5rem 1rem;
  }
  .page-navigation-list span.you-are-here {
    background: #193661;
    color: white;
  }

  .page-navigation-list span:active {
    background: #0055b1;
    color: white;
  }

  .page-navigation-list span:hover {
    cursor: pointer;
    background: #0055b1;
    color: white;
    font-weight: bold;
  }

  /**
  * navigation list hide
  */

  .page-navigation-list {
    max-height: 0;
    overflow: hidden;
  }
  .show-menu + .page-navigation-list {
    max-height: 100rem;
    transition: max-height 1000ms ease-in-out;
  }
}
/* end @media */

/* ==============================
new media for minimum width 768 pixel
============================== */

@media screen and (min-width: 768px) {
  .menubutton {
    display: none;
  }
  .page-navigation-list {
    max-height: none;
    overflow: auto;
    transition: none;

    flex-flow: row wrap;
  }
  .page-navigation {
    padding: 0;
  }
  .page-navigation > .wrapper {
    padding: 0 1rem;
  }
  .page-navigation-list li {
    display: inline-block;
    border-right: 1px solid #eee;
    flex: 1;
    text-align: center;
    max-width: 170px;
  }
  .page-navigation-list li:first-child {
    border-left: 1px solid #eee;
  }
}
/* end @media */
