/* ==============================
© Jens Geyer / 2021
header.css

- header stylesheet
============================== */

@media screen {
  .page-logo {
    display: block;
    margin-top: 15px;
    margin-left: 5px;
    margin-right: 0;
    margin-bottom: 15px;
  }
  .page-slogan {
    display: block;
    margin: 0;
  }
}
/* end @media */
