/* ==============================
© Jens Geyer / 2021
============================== */

@font-face {
  font-family: 'icomoon';
  src: url('./fonts/icomoon.eot?swohoa');
  src: url('./fonts/icomoon.eot?swohoa#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?swohoa') format('truetype'),
    url('./fonts/icomoon.woff?swohoa') format('woff'),
    url('./fonts/icomoon.svg?swohoa#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-contact:before {
  content: '\ea84';
}
.icon-me:before {
  content: '\e972';
}
.icon-workSamples:before {
  content: '\e930';
}
.icon-menu:before {
  content: '\e9bd';
}

.icon-designPatterns-file-pdf:before {
  content: '\eadf';
}

.icon-contact-data-whatsapp:before {
  content: '\ea93';
}

.icon-contact-data-info:before {
  content: '\ea0c';
}

.icon-contact-data-tel:before {
  content: '\e942';
}

.icon-contact-data-mail:before {
  content: '\ea86';
}

.icon-me-info2-content-li:before {
  content: '\ea3c';
}

.icon-me-info3-content-li:before {
  content: '\ea3c';
}

.contact-icon-data-color {
  font-size: 1rem;
  color: #0055b1;
}

.contact-form-icon-data-color {
  font-size: 0.9rem;
  color: #0055b1;
  padding-right: 0.3rem;
}

.font-color-heading-above {
  color: #193661;
}

/**
* hyperlinks with after
*/

.link-after {
  font-weight: bold;
}
.link-after:hover,
.link-after:focus {
  color: #193661;
  cursor: pointer;
}
.link-after:active {
  color: #000000;
}
.link-after::after {
  content: ' \29C9';
}

/**
* standard hyperlinks 
*/

.links-standard {
  text-decoration: underline;
}
.links-standard:hover,
.links-standard:focus {
  color: #193661;
  cursor: pointer;
}
.links-standard:active {
  color: #000000;
}

/**
* icons with hyperlinks 
*/

.links-icons {
  color: #3983d1;
  margin-bottom: 0.2rem;
}
.links-icons:hover,
.links-icons:focus {
  color: #193661;
  cursor: pointer;
}
.links-icons:active {
  color: #000000;
}

/**
* standard design patterns 
*/

.links-designPatterns {
  text-decoration: none;
}
.links-designPatterns:hover,
.links-designPatterns:focus {
  color: #193661;
  cursor: pointer;
}
.links-designPatterns:active {
  color: #000000;
}

/**
* icons with hyperlinks designPatterns
*/

.links-icons-designPatterns {
  color: #ff0000;
  margin-bottom: 0.2rem;
}
.links-icons-designPatterns:hover,
.links-icons-designPatterns:focus {
  color: #193661;
  cursor: pointer;
}
.links-icons-designPatterns:active {
  color: #000000;
}
