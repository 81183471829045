/* ==============================
© Jens Geyer / 2021
============================== */

@media screen {
  .me-info3-section {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .me-info3-content-ul {
    display: grid;
    grid-template-columns: 1rem auto;
    padding-left: 1rem;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  .me-info3-content-ul-child-1 {
    text-align: left;
  }

  .me-info3-content-ul-child-2 {
    text-align: left;
  }

  .icon-me-info3-content-li {
    list-style: none;
    font-size: 0.8rem;
    color: #0055b1;
  }

  .me-info3-content-li-span {
    font-size: 1rem;
    margin-left: 0.3rem;
  }
}
/* end @media */

@media screen and (min-width: 768px) {
  .me-info3-section {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
/* end @media */
