/* ==============================
© Jens Geyer / 2021
============================== */

@media screen {
  .me-distance {
    background-color: #193661;
    height: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .me-distance2 {
    height: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
/* end @media */

@media screen and (min-width: 768px) {
  .me-distance {
    background-color: #eee;
    height: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .me-distance2 {
    height: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
/* end @media */
