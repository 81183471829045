/* ==============================
© Jens Geyer / 2021
============================== */

@media screen {
  .me-info-section {
    margin-top: 2rem;
  }

  .me-info-content {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .me-info-span {
    font-weight: bold;
  }
  .me-info-content-1 {
    margin-bottom: 0.1rem;
  }
  .me-info-content-2 {
    margin-bottom: 1rem;
  }
  .me-info-content-3 {
    margin-bottom: 1rem;
  }
  .me-info-content-4 {
    margin-bottom: 1rem;
  }
  .me-info-content-5 {
    margin-bottom: 1rem;
  }

  .me-info-child-1 {
    text-align: left;

    border: 1px solid #193661;
    border-radius: 3px;
    padding: 0.5rem;
  }

  .me-info-child-2 {
    text-align: center;

    border: 1px solid #193661;
    border-radius: 3px;
    padding: 0.5rem;
    margin-bottom: 2rem;
  }
}
/* end @media */

@media screen and (min-width: 768px) {
  .me-info-section {
    margin-top: 1rem;
  }

  .me-info-content {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .me-info-span {
    font-weight: bold;
  }
  .me-info-content-1 {
    margin-bottom: 0.1rem;
  }
  .me-info-content-2 {
    margin-bottom: 1rem;
  }
  .me-info-content-3 {
    margin-bottom: 1rem;
  }
  .me-info-content-4 {
    margin-bottom: 1rem;
  }
  .me-info-content-5 {
    margin-bottom: 1rem;
  }

  .me-info {
    display: block;
  }

  .me-info-child-1 {
    text-align: left;

    border: 0;
    border-radius: 3px;
    padding: 0.5rem;
  }

  
}
/* end @media */
