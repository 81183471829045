/* ==============================
© Jens Geyer / 2021
============================== */

@media screen {
  .designPatterns-strategy-demo-section {
    margin-top: 2rem;
  }

  .designPatterns-strategy-demo-section > .wrapper {
    padding-left: 1;
    padding-right: 1;
  }

  .designPatterns-strategy-demo-content {
    background: #ffffff;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;

    text-align: left;
    margin-bottom: 2rem;
    border: 1px solid #193661;
    border-radius: 3px;
  }
}
/* end @media */

@media screen and (min-width: 768px) {
  .designPatterns-strategy-demo-section {
    margin-top: 1rem;
  }

  .designPatterns-strategy-demo-section > .wrapper {
    padding-left: 1rem;
    padding-right: 0;
  }

  .designPatterns-strategy-demo-content {
    background: #ffffff;
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    text-align: left;
    margin-bottom: 2rem;

    border-bottom: 1px solid #c0c0c0;
    border-radius: unset;
  }
}
/* end @media */
