/* ==============================
© Jens Geyer / 2021
============================== */

@media screen {
  .designPatterns-strategy-diagramm-section {
    margin-top: 2rem;
  }

  .designPatterns-strategy-diagramm-section > .wrapper {
    padding-left: 1;
    padding-right: 1;
  }

  .designPatterns-strategy-diagramm-content {
    background: #ffffff;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;

    text-align: center;
    margin-bottom: 1rem;
    border: 1px solid #193661;
    border-radius: 3px;
  }

  .designPatterns-strategy-diagramm-logo {
    margin-bottom: 1rem;
  }
  .designPatterns-strategy-diagramm-slogan {
    display: block;
    font-weight: bold;
    padding: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .designPatterns-strategy-diagramm-logo-2 {
    margin-bottom: 1rem;
  }
  .designPatterns-strategy-diagramm-slogan-2 {
    display: block;
    font-weight: bold;
    padding: 0;
    margin-bottom: 1rem;
  }
}
/* end @media */

@media screen and (min-width: 768px) {
  .designPatterns-strategy-diagramm-section {
    margin-top: 1rem;
  }

  .designPatterns-strategy-diagramm-section > .wrapper {
    padding-left: 1rem;
    padding-right: 0;
  }

  .designPatterns-strategy-diagramm-content {
    background: #ffffff;
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    text-align: center;
    margin-bottom: 2rem;

    border-bottom: 1px solid #c0c0c0;
    border-radius: unset;
  }

  .designPatterns-strategy-diagramm-logo {
    margin-bottom: 5rem;
  }
  .designPatterns-strategy-diagramm-slogan {
    display: block;
    font-weight: bold;
    padding: 0;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .designPatterns-strategy-diagramm-logo-2 {
    margin-bottom: 2rem;
  }
  .designPatterns-strategy-diagramm-slogan-2 {
    display: block;
    font-weight: bold;
    padding: 0;
    margin-bottom: 2rem;
  }
}
/* end @media */
