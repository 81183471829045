/* ==============================
© Jens Geyer / 2021
basis.css

- basic stylesheet
# layout area design
============================== */

@media screen {
  .app {
    font-family: 'Source Sans Pro', Arial, sans-serif;
    font-size: 1rem;
    background: white;
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-flow: column;
  }
  .wrapper {
    min-width: 320px;
    max-width: 575px;
    padding: 0 1rem;
    margin: 0 auto;
  }

  .wrapper-min-width {
    min-width: 320px;
  }

  /* for old browsers like the IE11 */
  header,
  nav,
  main,
  footer,
  section,
  article,
  aside,
  figcaption,
  figure {
    display: block;
  }

  /* avoidance of "collapsing margins" */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  blockquote {
    margin-top: 0;
    margin-bottom: 1em;
  }

  /* hide elements visually but leave them accessible for screen readers */
  .visually-hidden {
    position: absolute;

    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    margin: -1px;

    clip: rect(0 0 0 0);
    overflow: hidden;
    white-space: nowrap;
  }

  /**
  * basic text layout  
  * element selectors  
  */

  /* font sizes for headings */
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1rem;
    font-variant: small-caps;
  }

  /* basic design address */
  address {
    font-style: normal;
  }

  /**
  * head area   
  */

  .page-header {
    background: #193661;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  /**
  * content area   
  */

  .page-content {
    line-height: 1.5;
    margin-bottom: 1rem;
    flex: 1 auto;
  }

  /**
  * foot area   
  */

  .page-footer {
    font-size: smaller;
    background-color: #193661;
    padding: 1rem 0;
  }
}
/* end @media */

/* ==============================
new media for minimum width 768 pixel
============================== */

@media screen and (min-width: 768px) {
}
/* end @media */
/* end Media Query */

@supports (display: grid) {
  .wrapper {
    max-width: 960px;
  }
}
