/* ==============================
© Jens Geyer / 2021
============================== */

@media screen {
  .workSamples-menubutton {
    display: block;
    background: #0055b1;
    color: white;

    font-family: inherit;
    font-size: inherit;
    text-align: center;

    padding: 0.5rem 1rem;
    border: 0;
    border-radius: 0.25rem;
    margin-top: 0;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
  }

  .workSamples-navigation > .wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  /**
  * basic formatting 
  */

  .workSamples-navigation {
    position: -webkit-sticky;
    position: sticky;
    top: 0;

    background: #eee;
    color: white;

    box-shadow: 0 2px 6px #eee;

    padding: 0.5rem 0 0 0;
    margin: 0;

    z-index: 10;
  }

  /** 
  * design for list 
  */

  .workSamples-navigation-list {
    display: flex;
    flex-flow: column nowrap;

    padding: 0;
    margin: 0;
  }
  .workSamples-navigation-list li {
    list-style: none;
  }

  /**
  * design for links 
  */

  .workSamples-navigation-list span {
    display: block;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;

    min-width: 72px;
    background: #eee;
    color: #193661;

    padding: 0.5rem 1rem;
  }
  .workSamples-navigation-list span.workSamples-you-are-here {
    background: #c0c0c0;
    color: #193661;
  }

  .workSamples-navigation-list span:active {
    background: #dfdfdf;
    color: #193661;
  }

  .workSamples-navigation-list span:hover {
    cursor: pointer;
    background: #dfdfdf;
    color: #193661;
    font-weight: bold;
  }

  /**
  * navigation list hide
  */

  .workSamples-navigation-list {
    max-height: 0;
    overflow: hidden;
  }
  .workSamples-show-menu + .workSamples-navigation-list {
    max-height: 100rem;
    transition: max-height 1000ms ease-in-out;
  }
}
/* end @media */

/* ==============================
new media for minimum width 768 pixel
============================== */

@media screen and (min-width: 768px) {
  .workSamples-menubutton {
    display: none;
  }
  .workSamples-navigation-list {
    max-height: none;
    overflow: auto;
    transition: none;

    flex-flow: row wrap;
  }
  .workSamples-navigation {
    padding: 0;
  }
  .workSamples-navigation > .wrapper {
    padding: 0 1rem;
  }
  .workSamples-navigation-list li {
    display: inline-block;
    border: 1px solid #0055b1;
    border-radius: 3px;
    flex: 1;
    margin-right: 10px;
    text-align: center;
    max-width: 150px;
  }

  .workSamples-navigation-list span {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }
}
/* end @media */
