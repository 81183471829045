/* ==============================
© Jens Geyer / 2021
============================== */

@media screen {
  .contact-data-content {
    background: #eee;
  }

  .contact-data-span::after {
    content: '\A';
    white-space: pre;
  }

  .contact-data-span-heading {
    display: block;
    color: #193661;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .icon-contact-data-mail-span {
    color: #193661;
    font-weight: bold;
  }

  .contact-data a {
    color: #193661;
    text-decoration: none;
    font-weight: bold;
  }
  .contact-data a:hover,
  .contact-data a:focus {
    color: #0055b1;
    cursor: pointer;
  }
  .contact-data a:active {
    color: #000000;
  }

  .contact-data-tel {
    display: grid;
    grid-template-columns: 1.5rem auto;
    margin-top: 1rem;
  }
  .contact-data-tel-child-1 {
    text-align: left;
    margin-bottom: 0.2rem;
  }
  .contact-data-tel-child-2 {
    text-align: left;
    margin-bottom: 0.2rem;
  }
}
/* end @media */

@media screen and (min-width: 768px) {
  .contact-data-content {
    background: #eee;
  }

  .contact-data-span::after {
    content: '\A';
    white-space: pre;
  }

  .contact-data-span-heading {
    display: block;
    color: #193661;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .icon-contact-data-mail-span {
    color: #193661;
    font-weight: bold;
  }

  .contact-data a {
    color: #193661;
    text-decoration: none;
    font-weight: bold;
  }
  .contact-data a:hover,
  .contact-data a:focus {
    color: #0055b1;
    cursor: pointer;
  }
  .contact-data a:active {
    color: #000000;
  }

  .contact-data-tel {
    display: grid;
    grid-template-columns: 1.5rem auto;
    margin-top: 1rem;
  }
  .contact-data-tel-child-1 {
    text-align: left;
    margin-bottom: 0.2rem;
  }
  .contact-data-tel-child-2 {
    text-align: left;
    margin-bottom: 0.2rem;
  }
}
