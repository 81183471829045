/* ==============================
© Jens Geyer / 2021
============================== */

@media screen {
  .contact-map-content {
    background: #ffffff;
    margin-top: 1.5rem;
    padding: 0.5rem;
  }

  .contact-map-span-heading {
    display: block;
    color: #193661;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .leaflet-container {
    width: 100%;
    height: 250px;
    z-index: 0;
  }

  .contact-map-map {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 1rem;
    padding-top: 0.4rem;
  }
}
/* end @media */

@media screen and (min-width: 768px) {
  .contact-map-content {
    background: #ffffff;
    margin-top: 1.5rem;
    padding: 0.5rem;
  }

  .contact-map-span-heading {
    display: block;
    color: #193661;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .leaflet-container {
    width: 100%;
    height: 200px;
  }

  .contact-map-map {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
}
